var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"title":'替换门派',"visible":_vm.stageVisible,"close-on-click-modal":false,"width":"660px"},on:{"update:visible":function($event){_vm.stageVisible=$event},"closed":_vm.closed}},[_c('el-form',{ref:"stageForm",attrs:{"model":_vm.stageData,"label-width":"200px"}},[_c('el-form-item',{attrs:{"label":"当前门派"}},[_vm._v(" "+_vm._s(`编号：${_vm.stageData.from_sect_id} 名称:${_vm.stageData.from_sect_name}`)+" ")]),_c('el-form-item',{attrs:{"label":"替换门派","prop":"to_sect_id","rules":[
        {
          required: true,
          message: '请选择替换门派',
          trigger: ['blur', 'change'],
        },
      ]}},[_c('el-select',{attrs:{"filterable":"","remote":"","reserve-keyword":"","placeholder":"请输入6位门派编号","remote-method":_vm.remoteMethod,"loading":_vm.select_loading},model:{value:(_vm.stageData.to_sect_id),callback:function ($$v) {_vm.$set(_vm.stageData, "to_sect_id", $$v)},expression:"stageData.to_sect_id"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{staticStyle:{"text-align":"center"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.switchGroup}},[_vm._v("确定")]),_c('el-button',{on:{"click":function($event){_vm.stageVisible = false}}},[_vm._v("取消")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }