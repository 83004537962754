<template>
  <el-dialog
    :title="'替换门派'"
    :visible.sync="stageVisible"
    :close-on-click-modal="false"
    width="660px"
    v-loading="loading"
    @closed="closed"
  >
    <el-form ref="stageForm" :model="stageData" label-width="200px">
      <el-form-item label="当前门派">
        {{ `编号：${stageData.from_sect_id} 名称:${stageData.from_sect_name}` }}
      </el-form-item>
      <el-form-item
        label="替换门派"
        prop="to_sect_id"
        :rules="[
          {
            required: true,
            message: '请选择替换门派',
            trigger: ['blur', 'change'],
          },
        ]"
      >
        <el-select
          v-model="stageData.to_sect_id"
          filterable
          remote
          reserve-keyword
          placeholder="请输入6位门派编号"
          :remote-method="remoteMethod"
          :loading="select_loading"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item style="text-align: center">
        <el-button type="primary" @click="switchGroup">确定</el-button>
        <el-button @click="stageVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: "change_group",
  emits: ["changed", "closed"],
  computed: {
    groupsList: function() {
      let groups = this.groups.filter(
        (r) => r + "" != this.stageData.from_group + ""
      );
      return groups;
    },
  },
  props: {
    match_id: {
      type: String,
      required: true,
    },
    stage: {
      type: [String, Number],
      required: true,
    },
    from_data: {
      type: Object,
      required: true,
    },
    index: {
      type: [String, Number],
      required: false,
    },
  },
  watch: {
    from_data: {
      handler: function(val) {
        this.setData();
      },
      immediate: true,
    },
  },
  mounted() {
    this.setData();
  },
  data() {
    return {
      loading: false,
      select_loading: false,
      options: [],
      stageVisible: false,
      stageData: {
        from_id: "",
        from_group: "",
        from_sect_id: "",
        from_sect_name: "",
        to_sect_id: "",
      },
    };
  },
  methods: {
    closed() {
      this.$emit("closed");
    },
    setData() {
      if (this.from_data) {
        this.stageData.from_id = this.from_data._id;
        this.stageData.from_group = this.from_data.group;
        this.stageData.from_sect_id = this.from_data.sect_id;
        this.stageData.from_sect_name = this.from_data.sect_name;
      } else {
        this.stageData = {
          from_id: "",
          from_group: "",
          from_sect_id: "",
          from_sect_name: "",
          to_sect_id: "",
        };
      }

      this.stageVisible = true;
    },
    async remoteMethod(query) {
      if (query !== "" && query.length == 6) {
        this.select_loading = true;
        let param = {
          match_id: this.match_id,
          stage: this.stage,
          sect_id: query,
        };
        if (this.index) {
          param.index = this.index;
        }

        let { data, errorCode } = await this.$http.match.stage_group_team(
          param
        );
        this.select_loading = false;
        if (!data) {
          this.options = [];
        } else {
          let label = `${data.sect_id}|${data.sect_name}`;
          if (data.group && data.group > 0) {
            label += "|" + data.group + "组";
          }

          this.options = [
            {
              label: label,
              value: data.sect_id,
            },
          ];
        }
      } else {
        this.options = [];
      }
    },
    switchGroup() {
      this.$refs.stageForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let saveData = {
            match_id: this.match_id,
            stage: this.stage,
            from: this.stageData.from_id,
            to_sect_id: this.stageData.to_sect_id,
          };
          let { data, errorCode } =
            this.index && (this.index + "").length
              ? await this.$http.match.session_switch_group(saveData)
              : await this.$http.match.stage_switch_group(saveData);
          this.loading = false;

          if (errorCode != "0000") {
            return;
          }

          this.$message({
            type: "success",
            message: "替换成功",
          });

          this.$emit("changed", data);
          this.stageVisible = false;
          this.to_sect_id = "";
        }
      });
    },
  },
};
</script>

<style scoped></style>
